import {enviarDados} from "../../services/GenericAPI";

const url = 'localizacao'

const actions = {

  async enviarEmail({commit}, {localizacao, onSucesso, onErro}) {
    try {
      const res = await enviarDados(url + '/enviar', localizacao)
      // this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      console.log(error);
      // this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

export default {
  namespaced: true,
  actions
}
