/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
  // withCredentials: false,
  baseURL: process.env.VUE_APP_API_URL,
  BASE_URL: '/'

})

export function setToken(token) {
  API.defaults.headers.common.Authorization = `Bearer ${token}`
}
