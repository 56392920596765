<template xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <div>
    <v-snackbar v-model="show_alerta" :timeout="3000" top="top" :color="cor_alerta">
      {{ info_alerta }}
      <v-btn name="fechar"  text @click="fechar_alerta()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-card class="scroll-y">
      <v-app-bar absolute elevate-on-scroll scroll-target="#scrolling-techniques-7"
                 class="app_barr">
        <v-toolbar-title style="margin-left: 10%; ">
          <v-avatar class="profile" style="height: 60px;width: 110px; " tile>
            <v-img :src="img" style="height: 50px; width: 90px"></v-img>
          </v-avatar>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn dark color="green" @click="enviar()" :loading="loading" :disabled="disabled">
          <v-icon>mdi-whatsapp</v-icon>
          Enviar
        </v-btn>
      </v-app-bar>
      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="100vh" ref="app_bar">
        <div id="scroll-target" style="height: 100vh;" v-scroll:#scrolling-techniques-7="onScroll">
          <div id="topo">
            <router-view/>
          </div>
        </div>
      </v-sheet>
    </v-card>
    <v-btn v-scroll="onScroll" v-show="fab" small fab dark fixed bottom right color="red" @click="scrollTo('topo')">
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </div>
</template>

<script>
  /* eslint-disable */
  import img from './assets/img/logo.webp'
  import {mapActions} from "vuex";

  export default {
    name: 'Dash',
    data: () => ({
      fab: false,
      img,
      info_alerta: '',
      cor_alerta: 'primary',
      show_alerta: false,
      loading: false,
      disabled: false,
    }),

    methods: {
      ...mapActions('localizacao', ['enviarEmail']),
      open_alerta(text, cor) {
        this.info_alerta = text
        this.cor_alerta = cor
        this.show_alerta = true
        var _this = this
        var intervalo = setInterval(function () {
          _this.show_alerta= false
          clearInterval(intervalo)
        }, 5000)
      },
      fechar_alerta() {
        this.show_alerta = false
      },
      enviar() {

        this.loading = true
        let mensagem = sessionStorage.getItem('endereco') + ' LINK: ' + sessionStorage.getItem('link')
        let target = 'https://api.whatsapp.com/send?phone=553333226363&text=' + encodeURIComponent(mensagem)
        var a = document.createElement('a')
        a.href = target
        a.target = '_blank'
        a.click()

        let localizacao = {
          endereco: sessionStorage.getItem('endereco'),
          link: sessionStorage.getItem('link'),
        }
        this.enviarEmail({
          localizacao: localizacao,
          onSucesso: ()=>{
            this.disabled = true
            this.loading = false
            this.open_alerta('Mensagem enviada com sucesso', 'green')
          },
          onErro: ()=>{
            this.disabled = false
            this.loading = false
            this.open_alerta('Erro ao enviar mensagem', 'red')
          },
        })


      },
      onScroll(e) {
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > 20
      },
      scrollTo(id) {
        var elmnt = document.getElementById(id);
        elmnt.scrollIntoView({behavior: "smooth"});
      }
    },
  }
</script>
