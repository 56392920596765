/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../src/assets/css/main.scss'
import 'font-awesome/css/font-awesome.min.css'
import store from './store/index'
import {sync} from 'vuex-router-sync'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(Vuetify, {
  theme: {
    secondary: '#0a593b',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
})

Vue.config.productionTip = false

sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'
  }
})


new Vue({
  router,
  store: store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
