import Vue from 'vue'
import Vuex from 'vuex'
import localizacao from "./modules/localizacao";

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    localizacao
  }
})
